import React from 'react'

const CeoSecrets = () => (
  <iframe
    title={'Double CEO Secrets'}
    src={'/CEOs%20Best%20Practices.pdf'}
    frameBorder={'0'}
    allowFullScreen
    // @ts-ignore
    mozallowfullscreen
    webkitallowfullscreen
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
  />
)

export default CeoSecrets
